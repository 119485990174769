import Vue from "vue"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
import App from "./App.vue"
import DefaultLayout from "./layouts/Default.vue"
import DashboardLayout from "./layouts/Dashboard.vue"
import router from "./router"
import store from "./store"
// import './plugins/click-away'

import "./scss/app.scss"
import "./assets/fonts/fonts.css"

const script = document.createElement("script")
script.src = "https://img.nymaite.com/nymaite_com/static/m/js/jquery.min.js"
document.head.appendChild(script)

import { http } from "./api/api"
Vue.prototype.$http = http

import { BASE_API } from "../env"
Vue.prototype.$BASE_API = BASE_API

import VMdEditor from "@kangc/v-md-editor"
import "@kangc/v-md-editor/lib/style/base-editor.css"
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import Prism from "prismjs"
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index"
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css"
import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index"
import createTodoListPlugin from "@kangc/v-md-editor/lib/plugins/todo-list/index"
import "@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css"
VMdEditor.use(vuepressTheme, { Prism }).use(createCopyCodePlugin()).use(createLineNumbertPlugin()).use(createTodoListPlugin())
Vue.use(VMdEditor)

Vue.use(Antd)

import waterfall from "vue-waterfall2"
Vue.use(waterfall)

Vue.config.productionTip = false

Vue.component("layout-default", DefaultLayout)
Vue.component("layout-dashboard", DashboardLayout)

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount("#app")
